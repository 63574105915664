import React, { useEffect } from "react"
import { LayoutWhite } from "@layout"
import { HeroContact2022Q3 } from "@components/hero"
import { MainBlockContactForm } from "@components/main-blocks"
import SEO from "@components/seo"

const Contact = () => {
  useEffect(() => {
    window.location = "https://atm.atlassian.net/servicedesk/customer/portals"
  }, [])

  return (
    <LayoutWhite backgroundImage="animated" hero={<HeroContact2022Q3 />}>
      <SEO title="Contact Us" />
      <MainBlockContactForm />
    </LayoutWhite>
  )
}

export default Contact
